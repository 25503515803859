<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Desembolso - Ver</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/desembolso/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente:</label>
                    <v-select disabled placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" :options="clients"></v-select>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select disabled v-model="disbursement.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo:">
                    <b-form-select disabled v-model="disbursement.id_module" :options="modules"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro de Referencia:">
                    <b-form-input disabled type="text" v-model="disbursement.code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input disabled type="date" class="text-center" v-model="disbursement.date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="disbursement.amount"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Observación:">
                    <b-form-input disabled v-model="disbursement.description"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select disabled v-model="disbursement.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="OpenAnnexe" :disabled="disbursement.file.length == 0"  class="form-control" variant="warning" ><i class="fas fa-file"></i> Ver Anexo</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";// components
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "DisbursementAdd",
  props: ["id_disbursement"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Disbursement',
      role: 5,
      disbursement: {
          id_disbursement:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_module:'',
          code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          description:'',
          file:'',
          new_file:'',
          amount:'',
          state:1,
          created_at:'',
          updated_at:'',
      },
      file:null,
      modules:[],
      payment_method:[
        {value:'008', text:'EFECTIVO'},
        {value:'007', text:'CHEQUE'},
        {value:'003', text:'TRANSFERENCIA'},
      ],
      client: null,
      clients: [],
      state:[
        {value:'1',text:'Pendiente'},
        {value:'2',text:'Finalizado'},
        {value:'0',text:'Rechazado'},
      ],

      errors: {
        payment_method: false,
        date: false,
        amount: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListModule();
    this.ViewDisbursement();
  },
  methods: {
    ViewDisbursement,
    ListModule,
    OpenAnnexe,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    
  },
};

function ViewDisbursement() {
  let me = this;
  let id_disbursement = je.decrypt(this.id_disbursement);
  let url = me.url_base + "disbursement/view/"+id_disbursement;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.disbursement.id_disbursement = response.data.result.id_disbursement;
        me.disbursement.id_client = response.data.result.id_client;
        me.disbursement.id_user = response.data.result.id_user;
        me.disbursement.id_branch_office = response.data.result.id_branch_office;
        me.disbursement.id_module = response.data.result.id_module;
        me.disbursement.code = response.data.result.code;
        me.disbursement.payment_method = response.data.result.payment_method;
        me.disbursement.date = response.data.result.date;
        me.disbursement.description = response.data.result.description;
        me.disbursement.file = response.data.result.file;
        me.disbursement.amount = response.data.result.amount;
        me.disbursement.state = response.data.result.state;
        me.client = { value: response.data.result.id_client, text:response.data.result.name +" - "+ response.data.result.document_number}
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function OpenAnnexe() {
  let me = this;
  let url = me.url_base + this.disbursement.file;
  window.open(url,'_blank');
}

function ListModule() {
  let me = this;
  let url = me.url_base + "module/actives";
  me.modules = [{ value: '', text:'- Seleccione una opcion -'}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.modules.push({value: element.id_module, text: element.name });
      });
    }
  });
}

</script>
